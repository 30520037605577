export const getStylesObject = () => ({
    container: {
        height: '600px',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 24px',
        background: '#F8F7FA',
        minHeight: 0,
    },
    groupItemsAndUsersColumns: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '24px',
        backgroundColor: '#F8F7FA',
        height: '48vh',
    },
    nameAndPlatformWrapper: {
        display: 'grid',
        gridTemplateColumns: '66% 1fr',
        backgroundColor: '#F8F7FA',
        gap: '24px',
        paddingTop: '12px',
        height: '100%',
        minWidth: '540px',
    },
    nameAndPlatformColumns: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '24px',
    },
    typeAndActivityColumns: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '24px',
        backgroundColor: '#ffffff',
        marginBottom: '24px',
        paddingRight: '16px',
    },
    formRows: {
        display: 'grid',
        gridTemplateRows: '133px 102px 409px',
    },
    card: {
        boxShadow: 'none',
    },
    snackBar: {
        position: 'absolute',
        top: '-55px',
        right: '0px',
        width: '100%',
    },
});

export const variables = {
    card: {
        cardWidth: '100%',
        cardContentOverflow: 'visible',
        cardDividerColor: 'none',
        cardHeaderPadding: '8px 0px 0px 16px',
        cardContentPadding: '16px 16px 0px 16px'
    },
    dropdown: {
        dropdownWidth: '100%',
    },
};
