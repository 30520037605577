export const getStylesObject = () => ({
    horizontalInputRow: {
        display: 'grid',
        gridTemplateColumns: '1fr 20px',
        width: '100%',
        marginBottom: '5px',
    },
    overflow: {
        overflowY: 'auto',
        height: '288px',
    },
    checkbox: {
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: '1px solid',
        borderBottomColor: '#dbd9e0'
    },
    relative: {
        position: 'relative',
        right: '15px',
    },
    center: {
        width: '100%',
        height: '191px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    centerLine: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
