export enum GroupsType {
    Domain = "Domain",
    AdAccount = "Ad Account",
    ContentType = "Content Type",
}

export enum PlatformsType {
    Google = "Google",
    Facebook = "Facebook",
    Outbrain = "Outbrain",
    Taboola = "Taboola",
}

export enum ActivityType {
    EMPTY = '',
    PMWZNY = 'PMWZNY',
    PMWZ = 'PMWZ',
    GrowthFB = 'Growth-FB',
}

export enum GroupType {
    Domain = "domain",
    AdAccount = "adAccount",
    ContentType = "contentType",
}

export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}