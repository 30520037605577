import { HTTP_METHODS, mmNetworkService } from "../../core/network/mmServicesApiProvider";
import { CreativeInsightsItem } from "../../pages/creativeInsights/creativeInsights.types";
import { CreativeInsightsTimeRange } from "../../pages/creativeInsights/creativeInsights.enums";
import { formatTimeRangeForServer } from "./CreativeInsightsServiceFormatter";

const PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL = process.env.REACT_APP_PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL;

const FACEBOOK = 'facebook';
const TOP_ADS = 'top-ads';
const SEARCH = 'search';

export type CreativeInsightsResponse = {
  timeRange: CreativeInsightsTimeRange,
  ads: CreativeInsightsItem[]
}

export class CreativeInsightsServiceConfigsProvider {
  static creativeInsightsEndpoint(serverTimeRange: string) {
    return `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${FACEBOOK}/${TOP_ADS}/${SEARCH}/${serverTimeRange}`;
  }

  static getCreativeInsights(timeRange: CreativeInsightsTimeRange): Promise<CreativeInsightsResponse> {
    const serverTimeRange = formatTimeRangeForServer(timeRange);
    const url = CreativeInsightsServiceConfigsProvider.creativeInsightsEndpoint(serverTimeRange);
    const configs = {
      method: HTTP_METHODS.POST,
    };

    return mmNetworkService.fetch(url, configs);
  }
}
