/** @jsxImportSource @emotion/react */
import { useStyles } from "@ftbpro/mm-admin-core-hooks";
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, ButtonType, DropdownListItemProps, Filter, Loader, Modal, NoData, RowData, SearchInput, TableNew, Text } from "@ftbpro/mm-admin-ui-components";
import { capitalizeFirstLetter, isEmpty } from "@ftbpro/mm-admin-core-utils";
import { Group, User } from "../groups.types";
import { GroupsServiceConfigsProvider } from "../../../services/groups/GroupsServiceConfigProvider";
import { formatGroups } from "../../../services/groups/GroupsServiceFormatter";
import { getColumnsDefinition } from "./groupsTablePage.utils";
import { DeleteIcon, EditIcon } from "@ftbpro/mm-admin-assets";
import { getStylesObject, variables } from "./groupsTablePage.styles";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { useNavigateToPage, useOnArrowBackClick } from "../../../hooks/useNavigate";
import { useGroupsData } from "../groupsContext";
import { getUserDetails } from "../../../core/utils/user.utils";

export const GroupsTablePage = () => {
  const styles = useStyles(getStylesObject);
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);
  const [allGroups, setAllGroups] = useState<Group[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selecedUser, setSelectedUser] = useState<User | null>(null);
  const { groupData, setGroupData } = useGroupsData();
  const { users } = groupData;
  const userNames = useMemo(() => [{ value: 'All Users', id: 'all' }, ...users.map(user => ({ value: `${capitalizeFirstLetter(user.name)} (${user.email})`, id: user.id }))], [users]);

  const navigate = useNavigateToPage();

  useOnArrowBackClick('groups');

  useEffect(() => {
      getGroupsData()
      .catch(() => {
        setIsLoadingGroups(false);
      });
  }, []);

  useEffect(() => {
    if(!users.length ){
      getUsersList();
    };
  }, []);

  const getAllGroups =  useCallback(async (shouldFilter?: boolean) => {
    const groupsResponse = await GroupsServiceConfigsProvider.getGroups();
    setIsLoadingGroups(false);
    const updatedGroups = formatGroups(groupsResponse.groups);
    setAllGroups(updatedGroups);
    if(shouldFilter && (selecedUser || !isEmpty(searchValue))){
      const filteredGroups = filterGroupsByUserAndSearch(selecedUser, updatedGroups);
      setGroups(filteredGroups);
    }else{
      setGroups(updatedGroups);
    }
  }, [searchValue, selecedUser]);

  const getUserGroup = async () => {
        const userDetailes = await GroupsServiceConfigsProvider.getUserDetailes();
        setIsLoadingGroups(false);
        setAllGroups(userDetailes.groups);
        setGroups(formatGroups(userDetailes.groups)); 
  };

  const getGroupsData =  useCallback(async (shouldFilter?: boolean) =>   {
    setIsLoadingGroups(true);
    const userDetailes = getUserDetails();
    if(userDetailes.permissions.includes('group-management')){
      await getAllGroups(shouldFilter);
    }else if(userDetailes.permissions.includes('groups_owner')){
      await getUserGroup();
    }
  }, [getAllGroups]);

  const getUsersList = useCallback(async () => {
    setIsLoadingUsers(true);
    const usersResponse = await GroupsServiceConfigsProvider.getUsersList();
    setIsLoadingUsers(false);
    setGroupData({ ...groupData, users: usersResponse.users });
  }, [])

  const isMatchingSearchValue = (group: Group, currentSearchValue?: string) => {
    const value = currentSearchValue || searchValue;
    if (isEmpty(value)) return true;
    return group.items?.includes(value.toLowerCase()) || group.name.toLowerCase().trim() === value.toLowerCase().trim();
  }

  const filterGroupsByUser = (selectedUser: User | null) => {
    if (!selectedUser) {
      return allGroups;
    }
    return allGroups.filter(group => selectedUser?.groups.some(userGroup => userGroup.id === group.id));
  };

  const filterGroupsByUserAndSearch = (selectedUser: User | null, groups?: Group[], currentSearchValue?: string) => {
    const groupsToFilter = groups || allGroups;
    if (!selectedUser) {
      return groupsToFilter.filter(group => isMatchingSearchValue(group, currentSearchValue));
    }
     return groupsToFilter.filter(group => selectedUser.groups.some(userGroup => userGroup.id === group.id && isMatchingSearchValue(group, currentSearchValue)));

  };


  const onSelectedUserChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    const { id } = selectedItem as { id: string, value: string };
    if (id === 'all') {
      setGroups(allGroups);
      setSelectedUser(null);
    } else {
      const selectUser = users.find(user => user.id === id) as User;
      const filteredGroups = filterGroupsByUserAndSearch(selectUser);
      setGroups(filteredGroups);
      setSelectedUser(selectUser);
    }
  };

  const onCreateClick = () => {
    navigate('groups/new');
  };


  const  onSearchChange = (e: SyntheticEvent<HTMLElement, Event>, value = '') => {
    if (isEmpty(value)) {
      const filteredGroups = filterGroupsByUser(selecedUser);
      setGroups(filteredGroups);
      setSearchValue('');
    }
    else{
      setSearchValue(value);
      const filteredGroups = filterGroupsByUserAndSearch(selecedUser, undefined, value);
      setGroups(filteredGroups);
    }
  };

  return (
    <div css={styles.container}>
      <div css={styles.flexSpacesBetweenRow}>
        <div css={styles.titleAndFilterContainer}>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_XXXL}>Group Management</Text>
          <Filter
            loadingItems={isLoadingUsers}
            items={userNames}
            selectedValue={'All Users'}
            onSelectedChange={onSelectedUserChange}
            style={styles.filter}
            variables={variables.filter}
          />
          <SearchInput
          placeholder="Search Groups by Values"
          onChange={onSearchChange}
          onClear={onSearchChange}
          />
        </div>
        <Button content={"Create"}
          onClick={() => {
            onCreateClick()
          }}
          disabled={false}
        />
      </div>
      {isLoadingGroups
        ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />
        : <GroupsTableContent groupsTableData={groups} setIsLoading={setIsLoadingGroups} getGroupsData={getGroupsData} getUsersList={getUsersList}/>}
    </div>
  );
};


interface GroupsContentProps {
  groupsTableData: Group[];
  getGroupsData: (shouldFilter?: boolean ) => Promise<void>;
  setIsLoading: (val: boolean) => void;
  getUsersList : () => Promise<void>;
}

const GroupsTableContentComponent = ({ groupsTableData, getGroupsData, setIsLoading, getUsersList  }: GroupsContentProps) => {
  const [isModal, setIsModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<{ id: string, name: string }>({ id: '', name: '' });
  const navigate = useNavigateToPage();


  const styles = useStyles(getStylesObject);
  const columnsDefinition = getColumnsDefinition();

  const deleteGroup = async () => {
    setIsLoading(true);
    await GroupsServiceConfigsProvider.deleteGroup(selectedGroup.id);
    getUsersList();
    await getGroupsData(true);
    setIsModal(false);
  };

  const generateActionElementsFn = (data: RowData) => {
    return [{
      actionName: 'Edit',
      onClick: () => {
        navigate(`groups/${data.id}`);
      },
      icon: EditIcon,
    },
    {
      actionName: 'Delete',
      onClick: () => {
        setSelectedGroup({ id: data.id, name: data.name });
        setIsModal(true)
      },
      icon: DeleteIcon,
    },];
  };

  return (
    <div>
      {<Modal
        closeable
        onClose={() => setIsModal(false)}
        title="Delete Group"
        open={isModal}
      >
        <div>
          <Text
            type={Text.TEXT_TYPES.PARAGRAPH_L}>
            {`Are you sure you want to permanently delete "${selectedGroup.name}" group?`}
          </Text>
          <div css={styles.modalButtons}>
            <Button
              content="Cancel"
              onClick={() => setIsModal(false)}
              type={ButtonType.Secondary}
              style={styles.cancelButton}
            />
            <Button
              content="Delete"
              onClick={deleteGroup}
            />
          </div>
        </div>
      </Modal>}
      {isEmpty(groupsTableData)
        ? <div css={styles.noDataWrapper}><NoData /></div>
        : <div css={styles.tableWrapper}>
          <TableNew
            data={groupsTableData}
            columnsDefinition={columnsDefinition}
            defaultColDef={{ minWidth: 130 }}
            pagination
            generateActionElementsFn={generateActionElementsFn}
          />
        </div>
      }
    </div>
  );
};

const GroupsTableContent = React.memo(GroupsTableContentComponent);
