/** @jsxImportSource @emotion/react */
import { Card, Text } from "@ftbpro/mm-admin-ui-components";
import { GroupType } from "../../../groups.enums";
import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "@ftbpro/mm-admin-core-hooks";
import { groupTypedItem } from "../../../groups.types";
import { getStylesObject } from "./selectGroupType.styles";
import { getTypeName } from "../../../groups.utils";

interface SelectGroupTypeProps {
    selectedGroupType: GroupType,
    setSelectedGroupTypedItemList: Dispatch<SetStateAction<groupTypedItem[]>>,
    setSelectedGroupType: Dispatch<SetStateAction<GroupType>>,
    isEditing: boolean,
}


export const SelectGroupType = (props: SelectGroupTypeProps) => {
    const {
        selectedGroupType,
        setSelectedGroupTypedItemList,
        setSelectedGroupType,
        isEditing
    } = props

    const styles = useStyles(getStylesObject);
    return (
        <div>
            <Card
                title={(isEditing ? "" : "Select ") + "Group Type"}
                textType={Text.TEXT_TYPES.TITLE_S}
                style={styles.cardStyle}
                variables={styles.card}
            >
                <div css={styles.horizontalRadioButton}>
                    {Object.values(GroupType).map((groupType, idx) => (
                        <GroupTypeRadioButton key={idx}
                            groupType={groupType}
                            selectedGroupType={selectedGroupType}
                            setSelectedGroupType={setSelectedGroupType}
                            setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                            isEditing={isEditing}/>
                    ))}
                </div>

            </Card>
        </div>
    )
}

interface GroupTypeRadioButtonProps {
    isEditing: boolean,
    selectedGroupType: GroupType,
    groupType: GroupType,
    setSelectedGroupType: Dispatch<SetStateAction<GroupType>>,
    setSelectedGroupTypedItemList: Dispatch<SetStateAction<groupTypedItem[]>>,
}

export const GroupTypeRadioButton = (props: GroupTypeRadioButtonProps) => {
    const {
        isEditing,
        selectedGroupType,
        groupType,
        setSelectedGroupType,
        setSelectedGroupTypedItemList,
    } = props
    const isSelected = selectedGroupType === groupType;
    const styles = useStyles(getStylesObject, [isEditing, isSelected]);
    return (
        <span css={styles.flexCenter}>
            <input type="radio" id="`{groupType}-radio-button`"
                checked={isSelected}
                onClick={() => {
                    if (!isEditing) {
                        setSelectedGroupTypedItemList([]);
                        setSelectedGroupType(groupType);
                    }
                }}
                css={styles.radioButtonInput}
                readOnly={true}
                disabled={!isSelected && isEditing}/>
      <label htmlFor={`${groupType}-radio-button`} css={styles.radioButtonLabel}>
        {getTypeName(groupType)}
      </label>
    </span>
    )
}
