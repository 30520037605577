import { Group} from "../../pages/groups/groups.types";
import { GroupsType, PlatformsType } from "../../pages/groups/groups.enums";
import { DATE_FORMATS, formatDate } from "@ftbpro/mm-admin-core-utils";


export const formatGroups = (groupsList: Group[]) => {
  return groupsList.map(group => {
    const { type, platform, createdAt, ...rest } = group;
    return {
      ...rest,
      type: formatGroupType(type),
      platform: formatPlatformType(platform),
      createdAt: createdAt ? formatDate(createdAt, DATE_FORMATS.DATE) : '',
    }
  });
}

export const formatGroupType = (type: string): (GroupsType | string) => {
  switch (type) {
    case 'domain':
      return GroupsType.Domain;
    case 'adAccount':
      return GroupsType.AdAccount;
    case 'contentType':
      return GroupsType.ContentType;
    default:
      return '';
  }
};

export const formatPlatformType = (type: string): (PlatformsType | string) => {
  switch (type) {
    case 'google':
      return PlatformsType.Google;
    case 'facebook':
      return PlatformsType.Facebook;
    case 'outbrain':
      return PlatformsType.Outbrain;
    case 'taboola':
      return PlatformsType.Taboola;
    default:
      return '';
  }
};

