import React, {  useState } from 'react';
import { User } from './groups.types';
import { Outlet, useOutletContext } from 'react-router-dom';


type GroupsProps = {
 users: User[];
}

type GroupsContext = {
  groupData: GroupsProps;
  setGroupData: (data: GroupsProps) => void;
}

const initialState = {
    users: [],
}


export const GroupsProvider = () => {
  const [groupData, setGroupData] = useState<GroupsProps>(initialState);


  return (
      <Outlet context={{groupData, setGroupData}}/>
  );
};

export const useGroupsData = () => useOutletContext<GroupsContext>()
