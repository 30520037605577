export const getStylesObject = (style: any) => ({
  cellWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    height: '100px',
    whiteSpace: 'normal',
    boxSizing: 'border-box',
    ...style
  },
});
