import fetch from 'isomorphic-fetch';

export const HTTP_STATUS_CODES = {
    OK: 200,
    NO_CONTENT: 204,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    CONFLICT: 409,
};

export const HTTP_METHODS = {
    POST: 'POST',
    GET: 'GET',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
    HEAD: 'HEAD',
    OPTIONS: 'OPTIONS',
};

const FETCH_TIMEOUT = 60 * 1000;

export const PROMISE_STATUS = {
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected',
};

export class mmNetworkService {
    static getDefaultRequestConfig() {
        const token = JSON.parse(atob(localStorage.getItem('auth_app_token') || ''));
        return {
            headers: {
                Authorization: 'bearer ' + token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            method: 'GET',
            mode: 'cors',
            cache: 'default',
        };
    }

    static async performFetch({url, defaultConf, options = {}}) {
        let signal;
        let abortController;

        const {timeout = FETCH_TIMEOUT, ...inputConf} = options;
        const isAbortSupported = 'AbortController' in global;
        if (isAbortSupported) {
            abortController = new global.AbortController();
            ({signal} = abortController);
        }
        const conf = {...defaultConf, ...inputConf, signal: inputConf.signal ?? signal};
        const fetchPromise = fetch(url, conf)
            .then(async resp => {
                if (resp.status === HTTP_STATUS_CODES.NO_CONTENT) return '';

                if (resp.status === HTTP_STATUS_CODES.OK) {
                    return resp.json();
                }
                const resObject = await resp.json();
                return Promise.reject(resObject.error || resObject);
            })
            .then(res => res.data || res)
            .catch(errResp => {
                if (typeof errResp.code !== 'undefined' || errResp.code !== HTTP_STATUS_CODES.UNAUTHORIZED) {
                    console.info('Error request ', errResp, 'url=', url);
                } else {
                    console.info('Error request ', errResp);
                }
                const error = {
                    ...errResp,
                    status: errResp.code,
                };
                throw error;
            });

        if (isAbortSupported) {
            const timeoutHandler = setTimeout(
                () => {
                    abortController.abort();
                    clearTimeout(timeoutHandler);
                },
                timeout,
            );
        }
        return fetchPromise;
    }

    static fetch(url, options) {
        const defaultConf = this.getDefaultRequestConfig();
        return this.performFetch({url, defaultConf, options});
    }
}
