import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigateToPage = () => {
    const navigate = useNavigate();

    const navigateToPage = (url: string, options?: any) => {
        const newUrl = `/${url}`
        navigate(newUrl, options);
        window.parent.postMessage({ type: 'URL_CHANGE', url: newUrl }, '*');
    }
    return navigateToPage;
}


export const useOnArrowBackClick = (path: string) => {
    const navigate = useNavigate();
    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data?.type === 'arrow_back_event') {
                navigate(`/${path}`);
            }
        });
    }, []);
}