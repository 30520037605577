/** @jsxImportSource @emotion/react */
import { User } from "../../../groups.types";
import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "@ftbpro/mm-admin-core-hooks";
import { ActionsMenu, Card, Checkbox, Input, Loader, Text } from "@ftbpro/mm-admin-ui-components";
import { SearchIcon } from "@ftbpro/mm-admin-assets";
import { getStylesObject } from "./selectUsers.styles";
import { capitalizeFirstLetter } from "@ftbpro/mm-admin-core-utils";

interface SelectUsersProps {
    searchUsersItems: User[],
    setSearchUsersItems: Dispatch<SetStateAction<User[]>>,
    selectedUsers: User[],
    searchUsersValue: string,
    setSearchUsersValue: Dispatch<SetStateAction<string>>,
    usersList: User[],
    setSelectedUsers: Dispatch<SetStateAction<User[]>>,
    isEditing: boolean,
    isLoading: boolean,
}


export const SelectUsers = ({
    searchUsersItems,
    setSearchUsersItems,
    selectedUsers,
    searchUsersValue,
    setSearchUsersValue,
    usersList,
    setSelectedUsers,
    isLoading,
}: SelectUsersProps) => {
    const styles = useStyles(getStylesObject);

    const onUsersClick = (user: User) => {
        const isSelected = selectedUsers.some(userItem => userItem.id === user.id);
        if (!isSelected) {
            setSelectedUsers(val => {
                const updatedSelectedUsers = [...val, user];
                return updatedSelectedUsers;
            })
        } else {
            setSelectedUsers(val => {
                const updatedSelectedUsers = val.filter(userItem => userItem.email !== user.email);
                return updatedSelectedUsers;
            })
        }
    }

    const onClearAll = () => {
        searchUsersItems.forEach(searchItem => {
            const updatedSelectedUsersList = selectedUsers.filter(selectedUser => {
                return !searchUsersItems.some(searchUser => searchUser.id === selectedUser.id);
            });
            setSelectedUsers(updatedSelectedUsersList);
        })
    }
    const onIncludeAll = () => {
        const updatedSelectedUsersList = [...selectedUsers];

        searchUsersItems.forEach(searchItem => {
            const exists = selectedUsers.some(selectedUser => selectedUser.id === searchItem.id);

            if (!exists) {
                updatedSelectedUsersList.push(searchItem);
            }
        });

        setSelectedUsers(updatedSelectedUsersList);
    }
    return (
        <span>
            <Card
                title={"Select Users" + (selectedUsers.length ? " (" + selectedUsers.length + ")" : '')}
                textType={Text.TEXT_TYPES.TITLE_S}
                variables={{
                    cardHeight: '422px',
                }}
                style={styles.cardStyle}
            >
                {
                    isLoading ?
                    <div css={styles.loaderContainer}>
                       <Loader size={Loader.LOADER_SIZES.SMALL} />
                    </div>
                        :
                        <div css={styles.horizontalInputRow}>

                                <Input
                                    clearable
                                    onClear={() => {
                                        setSearchUsersValue('');
                                        setSearchUsersItems(usersList);
                                    }}
                                    style={{ width: '93%' }}
                                    icon={SearchIcon}
                                    placeholder="Search Users"
                                    onChange={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                                        const inputElement = e.target as HTMLInputElement;
                                        setSearchUsersValue(inputElement.value);
                                        if (!inputElement.value) {
                                            setSearchUsersItems(usersList);
                                        } else {
                                            setSearchUsersItems(usersList.filter(user => user.email.includes(inputElement.value)));
                                        }
                                    }}
                                    value={searchUsersValue}
                                    variables={{
                                        borderColor: '#e4ecff'
                                    }}
                                    autoHeight
                                />
                            <span css={styles.centerLine}>
                                {usersList.length > 0 && <ActionsMenu
                                    style={styles.actionMenu}
                                    elements={[
                                        {
                                            actionName: 'Clear All',
                                            disabled: false,
                                            onClick: () => {
                                                onClearAll();
                                            }
                                        },
                                        {
                                            actionName: 'Include All',
                                            onClick: () => {
                                                onIncludeAll();
                                            }
                                        },
                                    ]}
                                    itemId="id"
                                />}
                            </span>
                        </div>
                }


                <div css={styles.overflow}>
                    {usersList.length > 0 && usersList.map((user) => (
                    (user.email.toLowerCase().includes(searchUsersValue.toLowerCase()) ||  user.name.toLowerCase().includes(searchUsersValue.toLowerCase()))&&
                        <div key={user.id} css={styles.checkbox}>
                            <Checkbox
                                checked={selectedUsers.some(userItem => userItem.id === user.id)}
                                onChange={() => onUsersClick(user)}
                            >
                                {capitalizeFirstLetter(user.name)} ({user.email})
                            </Checkbox>
                        </div>
                    ))}
                </div>
            </Card>
        </span>
    )
};
