/** @jsxImportSource @emotion/react */
import React from "react";
import { useStyles } from '@ftbpro/mm-admin-core-hooks';
import { getStylesObject } from "./GenericCellWrapper.style";

export const GenericCellWrapper = (props: { valueComponent: JSX.Element }) => {
    const styles = useStyles(getStylesObject);
    return (
        <div css={styles.genericCellWrapper}>
            {props.valueComponent}
        </div>
    );
};