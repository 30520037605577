export enum CreativeInsightsTimeRange {
  Yesterday = "Yesterday",
  Last7Days = "Last 7 days",
  Last30Days = "Last 30 days",
}

export enum CreativeType {
  Classic = 'Classic',
  Dynamic = 'Dynamic',
}

export enum Statuses {
  New = 'new',
  Draft = 'draft',
  Pending = 'pending',
  Success = 'success',
  Failure = 'error',
  Archived = 'archived',
}
