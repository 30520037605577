export const getStylesObject = (isEditing: boolean, isSelected: boolean) => ({
    horizontalRadioButton: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
        cursor: isEditing ? 'default' : 'pointer'
    },
    radioButtonInput: {
        cursor: isEditing ? 'default' : 'pointer',
        accentColor: isEditing ? 'grey' : 'none',
    },
    radioButtonLabel: {
        marginLeft: '3px',
        cursor: isEditing ? 'default' : 'pointer',
        textWrap: 'nowrap',
        fontSize: '14px',
        color: (!isSelected && isEditing) ? '#a79e9ec9' : 'black'
    },
    card: {
        cardWidth: '100%',
        cardContentOverflow: 'visible',
        cardDividerColor: 'none',
        cardHeaderPadding: '8px 0px 0px 16px',
    },
    cardStyle: {
        marginBottom: '24px',
        boxShadow: 'none',
        paddingRight: '0px',
    }
});