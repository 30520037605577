/** @jsxImportSource @emotion/react */
import { dateCellComparator, dateCellSorter } from "../../../core/utils/utils";
import { SortDirection } from "../groups.enums";


export const getColumnsDefinition = () => [
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'platform',
        headerName: 'Platform',
    },
    {
        field: 'type',
        headerName: 'Type',
    },
    {
        field: 'activity',
        headerName: 'Activity',
    },
    {
        field: 'createdAt',
        headerName: 'Created Date',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: dateCellComparator,
        },
        comparator: dateCellSorter,
        sort: SortDirection.Desc,
    },
];
