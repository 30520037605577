export const getStylesObject = () => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    background: '#F8F7FA',
    overflow: 'hidden',
    minHeight: 0,
  },
  imageWrapper: {
    width: '80px',
    height: '80px',
    backgroundColor: '#E5E5E5',
    marginTop: '10px',
    textAlign: 'center',
    cursor: 'pointer',

  },
  image: {
    height: '100%',
    verticalAlign: 'top',
  },
  tooltipImageWrapper: {
    width: '500px',
    height: '250px',
    backgroundColor: '#4C4C4C',
    display: 'flex',
    padding: '8px',
    justifyContent: 'center',
    overflow: 'visible !important',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  noDataWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    backgroundColor: '#FFF',
  },
  tableWrapper: {
    overflowY: 'auto',
    height: '100vh',
  },
  filter: {
    position: 'relative',
    top: '20px',
    left: '400px',
    display: 'flex',
  },
  orderByFilter: {
     marginLeft: '40px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    height: '96px',
    paddingLeft: '5px',
    width: '-webkit-fill-available',
  },
  rankColumn: {
   alignItems: 'center',
  }
});
