export const getStylesObject = () => ({
  genericCellWrapper: {
    display: 'flex',
    whiteSpace: 'nowrap !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    justifyContent: 'left',
    alignItems: 'center',
    height: '100px',
  },
});
