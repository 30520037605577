/** @jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from '@ftbpro/mm-admin-core-hooks';
import { Text } from "@ftbpro/mm-admin-ui-components";
import { getStylesObject } from './TextCellWapper.style';

export const TextCellWrapper = (params: { value: string }, style?: Record<string, unknown>  ) => {
  const styles = useStyles(getStylesObject, [style]);

  return (
    <div css={styles.cellWrapper}>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} rows={4}>{params.value}</Text>
    </div>
  )
}
