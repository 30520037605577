/** @jsxImportSource @emotion/react */
import { useStyles } from "@ftbpro/mm-admin-core-hooks";
import React, { useEffect, useState } from "react";
import { groupTypedItem, User } from "../groups.types";
import { GroupsServiceConfigsProvider } from "../../../services/groups/GroupsServiceConfigProvider";
import {
    Button,
    Card,
    Dropdown,
    Input,
    Loader,
    PageHeader,
    RequiredIndicatorType,
    SNACK_BAR_STATUSES,
    SnackBar,
    Text,
    Types
} from "@ftbpro/mm-admin-ui-components";
import { ActivityType, GroupType, PlatformsType } from "../groups.enums";
import { SelectGroupType } from "./components/selectGroupType/SelectGroupType";
import { SelectGroupItem } from "./components/selectGroupItem/SelectGroupItem";
import { SelectUsers } from "./components/selectUsers/SelectUsers";
import { SelectionSummary } from "./components/summary/SelectionSummary";
import { getStylesObject, variables } from "./groupAdjustPage.styles";
import { useParams } from "react-router-dom";
import { useNavigateToPage } from "../../../hooks/useNavigate";
import { useGroupsData } from "../groupsContext";

export const GroupsAdjustPage = () => {
    const styles = useStyles(getStylesObject);
    const [isLoading, setIsLoading] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState<string>('');
    const [selectedActivity, setSelectedActivity] = useState<string>('');
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [searchUsersValue, setSearchUsersValue] = useState<string>('');
    const [searchUsersItems, setSearchUsersItems] = useState<User[]>([]);
    const [selectedGroupType, setSelectedGroupType] = useState<GroupType>(GroupType.Domain);
    const [selectedGroupTypedItemList, setSelectedGroupTypedItemList] = useState<groupTypedItem[]>([]);
    const [groupName, setGroupName] = useState<string>('');
    const { id: groupId } = useParams<{ id: string }>();
    const { groupData, setGroupData } = useGroupsData();
    const { users } = groupData;
    const navigate = useNavigateToPage();



    const isEditing = !!groupId;

    useEffect(() => {
        if (isEditing) {
            getGroup();
        }
        getUsersList();
    }, []);


    const publishGroup = async () => {
        setIsLoading(true);
        let groupsResponse = null;
        let errorOccurred = false;

        try {
            if (isEditing) {
                groupsResponse = await GroupsServiceConfigsProvider.updateGroup(
                    groupId,
                    groupName,
                    selectedGroupType,
                    selectedPlatform,
                    selectedGroupTypedItemList.map(item => item.item),
                    selectedActivity
                );
            } else {
                groupsResponse = await GroupsServiceConfigsProvider.createGroup(
                    groupName,
                    selectedGroupType,
                    selectedPlatform,
                    selectedGroupTypedItemList.map(item => item.item),
                    selectedActivity
                );
            }

            if ((groupsResponse?.id || groupId) && selectedUsers) {
                await GroupsServiceConfigsProvider.adjustUsersList(
                    (groupId || groupsResponse?.id),
                    selectedUsers.map(user => user.id)
                );
            }
        } catch (error: any) {
            errorOccurred = true;
            if (error?.code === 409) {
                setErrorMessage(error?.messages || '');
            }
            setIsError(true);
        }

        setIsLoading(false);
        getUsers();
        if (!errorOccurred) {
            navigate('groups');
        }
    };


    const getUsers = async () => {
        const usersResponse = await GroupsServiceConfigsProvider.getUsersList();
        setGroupData({ ...groupData, users: usersResponse.users });
        setSearchUsersItems(usersResponse.users || []);
    }

    const getSelectedUsers = async (selectedGroupId: string) => {
        const selectedUsersListResponse = await GroupsServiceConfigsProvider.getSelectedUsersList(selectedGroupId);
        if (selectedUsersListResponse.users) {
            setSelectedUsers(selectedUsersListResponse.users);
        }
    }

    const getUsersAndSelectedUsers = async (selectedGroupId: string) => {
        const users = GroupsServiceConfigsProvider.getUsersList();
        const selectedUsers = GroupsServiceConfigsProvider.getSelectedUsersList(selectedGroupId);
        const results = await Promise.all([users, selectedUsers]);
        setGroupData({ ...groupData, users: results[0].users });
        setSelectedUsers(results[1].users || []);
    }

    const getUsersList = async () => {
        if (!users.length) {
            setIsUsersLoading(true);
            if (isEditing) {
                await getUsersAndSelectedUsers(groupId);
            } else {
                await getUsers();
            }
            setIsUsersLoading(false);
        } else {
            if (isEditing) {
                setIsUsersLoading(true);
                await getSelectedUsers(groupId);
                setIsUsersLoading(false);
            }
            if (!searchUsersItems.length) {
                setSearchUsersItems(users);

            }
        }
    };

    const getGroup = async () => {
        if (groupId) {
            setIsLoading(true);
            const getGroupResponse = await GroupsServiceConfigsProvider.getGroup(groupId);
            setGroupName(getGroupResponse.name);
            setSelectedGroupType(getGroupResponse.type as GroupType);
            setSelectedPlatform(getGroupResponse.platform);
            setSelectedActivity(getGroupResponse?.activity || '')
            if (getGroupResponse.items) {
                const items: groupTypedItem[] = getGroupResponse.items.map((itemName) => {
                    return { item: itemName }
                });
                setSelectedGroupTypedItemList(items);
            }
            setIsLoading(false);
        }
    }
    return (
        <>
            <PageHeader title={isEditing ? "Edit Group" : "New Group"} onBackClicked={() => navigate('groups')}>
                <Button onClick={publishGroup}
                    disabled={!groupName || !selectedGroupTypedItemList.length}>{isEditing ? "Save Changes" : "Create"}</Button>
            </PageHeader>
            <div css={styles.container}>
                {isError && <div css={styles.snackBar}>
                    <SnackBar
                        text={errorMessage || 'Oops, something went wrong'}
                        status={SNACK_BAR_STATUSES.ERROR}
                        stayOpen={true}
                        variables={{ animationDuration: '2s' }}
                        onClose={() => {
                            setIsError(false);
                            setErrorMessage('');
                        }}
                    />
                </div>}
                {isLoading
                    ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />
                    : <div css={styles.nameAndPlatformWrapper}>
                        <div css={styles.formRows}>
                            <Card
                                title="Name & Platform"
                                textType={Text.TEXT_TYPES.TITLE_S}
                                variables={variables.card}
                                style={styles.card}
                            >
                                <div css={styles.nameAndPlatformColumns}>
                                    <Input
                                        clearable
                                        onClear={() => {
                                            setGroupName('');
                                        }}
                                        labelProps={{
                                            text: 'Name',
                                            requiredIndicator: isEditing ? RequiredIndicatorType.None : RequiredIndicatorType.Required,
                                        }}
                                        placeholder="My new group"
                                        onChange={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                                            const inputElement = e.target as HTMLInputElement;
                                            setGroupName(inputElement.value);
                                        }}
                                        value={groupName}
                                        autoHeight
                                        readOnly={isEditing}
                                    />
                                    <Dropdown
                                        placeholder={'Choose Platform'}
                                        readOnly={isEditing}
                                        items={Object.values(PlatformsType)}
                                        selectedValue={selectedPlatform}
                                        labelProps={{
                                            requiredIndicator: isEditing ? RequiredIndicatorType.None : RequiredIndicatorType.Required,
                                            text: 'Platform',
                                        }}
                                        search
                                        variables={variables.dropdown}
                                        onSelectedChange={(e: Types.ClickEventType<HTMLElement>, platform: any) => {
                                            setSelectedGroupTypedItemList([]);
                                            setSelectedPlatform(platform.value.toLowerCase());
                                        }}
                                    />
                                </div>
                            </Card>

                            <div css={styles.typeAndActivityColumns}>

                                <SelectGroupType selectedGroupType={selectedGroupType}
                                    setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                                    setSelectedGroupType={setSelectedGroupType} isEditing={isEditing} />

                                {selectedGroupType === GroupType.Domain && <Dropdown
                                    placeholder={'Select Activity'}
                                    items={Object.values(ActivityType)}
                                    selectedValue={selectedActivity}
                                    style={{ paddingBottom: '28px' }}
                                    labelProps={{
                                        requiredIndicator: RequiredIndicatorType.Optional,
                                        text: 'Activity',
                                    }}
                                    search
                                    variables={variables.dropdown}
                                    onSelectedChange={(e: Types.ClickEventType<HTMLElement>, activity: any) => {
                                        setSelectedActivity(activity.value);
                                    }}
                                />}

                            </div>

                            <div css={styles.groupItemsAndUsersColumns}>
                                <SelectGroupItem
                                    selectedGroupType={selectedGroupType}
                                    selectedGroupTypedItemList={selectedGroupTypedItemList}
                                    setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                                    isEditing={isEditing}
                                    selectedPlatform={selectedPlatform}
                                />
                                <SelectUsers
                                    searchUsersItems={searchUsersItems}
                                    setSearchUsersItems={setSearchUsersItems}
                                    selectedUsers={selectedUsers}
                                    searchUsersValue={searchUsersValue}
                                    setSearchUsersValue={setSearchUsersValue}
                                    usersList={users}
                                    setSelectedUsers={setSelectedUsers}
                                    isEditing={isEditing}
                                    isLoading={isUsersLoading}
                                />
                            </div>
                        </div>
                        <SelectionSummary
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            selectedGroupType={selectedGroupType}
                            selectedGroupTypedItemList={selectedGroupTypedItemList}
                            setSelectedGroupTypedItemList={setSelectedGroupTypedItemList}
                        />
                    </div>}
            </div>
        </>

    );
};
