import { GroupType } from "./groups.enums";

export const getTypeName = (selectedGroupType: GroupType) => {
    switch (selectedGroupType) {
        case GroupType.Domain:
            return 'Domains';
        case GroupType.ContentType:
            return 'Content Type';
        case GroupType.AdAccount:
            return 'Ad Accounts';
        default:
            return '';
    }
}

