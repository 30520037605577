import { HTTP_METHODS, mmNetworkService } from "../../core/network/mmServicesApiProvider";
import { Group, User } from "../../pages/groups/groups.types";


const PM_USER_SERVICE_BASE_URL = process.env.REACT_APP_PM_USER_SERVICE_BASE_URL;

const GROUPS = 'groups';
const GROUP_ITEMS = 'group-items';
const USERS = 'users';
const AUTH = 'auth';

interface Config {
    name: string;
    type: string;
    platform: string;
    items: any[];
    activity?: string;
}

export type GroupsResponse = {
    groups: [];
}

export type UsersListResponse = {
    users: User[];
}

export type GroupsDeleteResponse = {
    data: string | null;
}
export type GroupsItemsResponse = {
    items: { item: string }[];
}
export type SelectedUsersListResponse = {
    users: User[];
}


export type GetGroupResponse = Group;

export class GroupsServiceConfigsProvider {
    static groupsEndpoint(action?: string, id?: string) {
        if (action === 'delete') {
            return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}/${id}`;
        }
        return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}`;
    }

// GROUPS

    static getGroups(): Promise<GroupsResponse> {
        const url = GroupsServiceConfigsProvider.groupsEndpoint();
        const configs = {
            method: HTTP_METHODS.GET,
        };

        return mmNetworkService.fetch(url, configs);
    }

    static deleteGroup(id: string): Promise<GroupsDeleteResponse> {
        const url = GroupsServiceConfigsProvider.groupsEndpoint('delete', id);
        const configs = {
            method: HTTP_METHODS.DELETE,
        };

        return mmNetworkService.fetch(url, configs);
    }

//GROUP ITEMS
    static groupItemsEndpoint(groupType: string, platform: string) {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUP_ITEMS}?type=${groupType}&platform=${platform}`;
    }

    static getGroupItems(groupType: string, platform: string): Promise<GroupsItemsResponse> {
        const url = GroupsServiceConfigsProvider.groupItemsEndpoint(groupType, platform.toLowerCase());
        const configs = {
            method: HTTP_METHODS.GET,
        };

        return mmNetworkService.fetch(url, configs);
    }

// GROUP CREATE
    static createGroupEndpoint() {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}`;
    }

    static createGroup(name: string, type: string, platform: string, items: string[], selectedActivity: string) {
        const url = GroupsServiceConfigsProvider.createGroupEndpoint();

        const config: Config = {
            name: name,
            type: type,
            platform: platform,
            items: items,
        }


        if (selectedActivity && selectedActivity !== '') {
            config.activity = selectedActivity;
        }

        const configs = {
            method: HTTP_METHODS.POST,
            body: JSON.stringify(config),
        };
        return mmNetworkService.fetch(url, configs);
    }

//USERS

   static getUserDetailes(){
    const url =  `${PM_USER_SERVICE_BASE_URL}/${AUTH}`;

    const configs = {
        method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
   }
   
    static usersListEndpoint() {
        return `${PM_USER_SERVICE_BASE_URL}/${USERS}`;
    }

    static getUsersList(): Promise<UsersListResponse> {
        const url = GroupsServiceConfigsProvider.usersListEndpoint();

        const configs = {
            method: HTTP_METHODS.GET,
        };

        return mmNetworkService.fetch(url, configs);
    }

    static selectedUsersListEndpoint(groupId: string) {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}/${groupId}/${USERS}`;
    }

    static getSelectedUsersList(groupId: string): Promise<SelectedUsersListResponse> {
        const url = GroupsServiceConfigsProvider.selectedUsersListEndpoint(groupId);

        const configs = {
            method: HTTP_METHODS.GET,
        };

        return mmNetworkService.fetch(url, configs);
    }

    static adjustUsersListEndpoint(groupId: string) {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}/${groupId}/${USERS}`;
    }

    static adjustUsersList(groupId: string, usersIdList: string[]): Promise<UsersListResponse> {
        const url = GroupsServiceConfigsProvider.adjustUsersListEndpoint(groupId);
        const config = {
            userIds: usersIdList
        }
        // POST /groups/:id/users
        const configs = {
            method: HTTP_METHODS.POST,
            body: JSON.stringify(config),
        };

        return mmNetworkService.fetch(url, configs);
    }

    static adjustGetGroupEndpoint(groupId: string) {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}/${groupId}`;
    }

    static getGroup(groupId: string): Promise<GetGroupResponse> {
        const url = GroupsServiceConfigsProvider.adjustGetGroupEndpoint(groupId);
        const configs = {
            method: HTTP_METHODS.GET,
        };
        return mmNetworkService.fetch(url, configs);
    }

    static updateGroupEndpoint(groupId: string) {
        return `${PM_USER_SERVICE_BASE_URL}/${GROUPS}/${groupId}`;
    }

    static updateGroup(groupId: string, name: string, type: string, platform: string, items: string[], selectedActivity: string) {
        const url = GroupsServiceConfigsProvider.updateGroupEndpoint(groupId);

        const config: Config = {
            name: name,
            type: type,
            platform: platform,
            items: items,
            ...(selectedActivity && { activity: selectedActivity }),
        }
        const configs = {
            method: HTTP_METHODS.PUT,
            body: JSON.stringify(config),
        };
        return mmNetworkService.fetch(url, configs);
    }


}
