import { CreativeInsightsItem } from "../../pages/creativeInsights/creativeInsights.types";
import { CreativeInsightsTimeRange, CreativeType } from "../../pages/creativeInsights/creativeInsights.enums";
import { capitalizeFirstLetter, DATE_FORMATS, formatDate } from "@ftbpro/mm-admin-core-utils";


export const formatCreativeInsights = (creativeInsightsList: CreativeInsightsItem[]) => {
  return creativeInsightsList.map((creativeInsight, index) => {
    const { createdAt, externalID, id, type, effectiveStatus, ...rest } = creativeInsight;
    return {
      ...rest,
      id: id || `external${externalID}`,
      type: formatCreativeTypeForClient(type),
      createdAt: createdAt ? formatDate(createdAt, DATE_FORMATS.DATE) : '',
      externalID,
      effectiveStatus: formatStatusForClient(effectiveStatus || ''),
      costRanking: index + 1,
    };
  });
}

export const formatCreativeTypeForClient = (type: string): (CreativeType | string) => {
  switch (type) {
    case 'classic':
      return CreativeType.Classic;
    case 'dynamic':
      return CreativeType.Dynamic;
    default:
      return '';
  }
}

const formatStatusForClient = (status: string): string => {
  return capitalizeFirstLetter(status.replace(/_/g, ' ').toLowerCase());
}

export const formatTimeRangeForServer = (timeRange: CreativeInsightsTimeRange) => {
  return timeRange.replace(/\s+/g, '-').toLowerCase();
}

