/** @jsxImportSource @emotion/react */
import { ButtonType, IconButton, Image } from '@ftbpro/mm-admin-ui-components';
import { TextCellWrapper } from '../../components/TextCellWapper/TextCellWrapper';
import {
  dateCellComparator,
  dateCellSorter,
  formatNumberToIncludeCommas,
  numberFormatter,
  signedValue
} from '../../core/utils/utils';
import { GenericCellWrapper } from '../../components/ GenericCellWrapper/GenericCellWrapper';
import { ExternalLinkIcon } from '@ftbpro/mm-admin-assets';
import React from 'react';

const onExternalLinkClick = (url: string) => {
  window.open(url, '_blank');
};

export const getColumnsDefinition = (styles: any, displaySensitiveMetrics: boolean, totalRowsCount: number) => {

  const allColumnsDefinition = [
    {
      field: 'costRanking',
      headerName: 'Top Spending Rank',
      minWidth: 120,
      cellRenderer: (params: { value: string }) => {
        const rankParams = { value: `${params.value}/${totalRowsCount}` };
        return TextCellWrapper(rankParams, styles.rankColumn);
      }
    },
    {
      field: 'primaryAssets.imageURL',
      headerName: 'Image',
      minWidth: 200,
      cellRenderer: (params: { value: string }) => (
        <div css={styles.imageWrapper} onClick={() => onExternalLinkClick(params.value)}>
          <Image src={params.value} style={styles.image}/>
        </div>
      ),
      tooltipField: 'primaryAssets.imageURL',
      tooltipComponent: (params: { value: string }) => (
        <div css={styles.tooltipImageWrapper}>
          <Image src={params.value} style={styles.image}/>
        </div>
      ),
      filter: false,
    },
    {
      field: 'primaryAssets.text',
      headerName: 'Primary Text',
      minWidth: 248,
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'primaryAssets.headline',
      headerName: 'Headline',
      minWidth: 248,
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'articleName',
      headerName: 'Article Name',
      minWidth: 150,
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'adsetName',
      headerName: 'Ad Set Name',
      minWidth: 150,
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'account.name',
      headerName: 'Account Name',
      minWidth: 150,
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'externalID',
      headerName: 'Ad ID',
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'metrics.cost',
      headerName: 'Cost',
      cellRenderer: (params: any) => {
        const value = numberFormatter(params.value);
        return TextCellWrapper(signedValue(value, '$'));
      },
      filter: 'agNumberColumnFilter',
    },

    {
      field: 'metrics.impressions',
      headerName: 'Impressions',
      cellRenderer: (params: any) => TextCellWrapper({ value: formatNumberToIncludeCommas(params.value) }),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'metrics.linkClicks',
      headerName: 'Clicks',
      cellRenderer: (params: any) => TextCellWrapper({ value: formatNumberToIncludeCommas(params.value) }),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'metrics.ctr',
      headerName: 'CTR',
      cellRenderer: (params: any) => {
        const value = numberFormatter(params.value);
        return TextCellWrapper(signedValue(value, '%'));
      },
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'metrics.cpc',
      headerName: 'CPC',
      cellRenderer: (params: any) => {
        const value = numberFormatter(params.value);
        return TextCellWrapper(signedValue(value, '$'));
      },
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'metrics.adsetRevenue',
      headerName: 'Revenue (ad set)',
      cellRenderer: (params: any) => {
        const value = numberFormatter(params.value);
        return TextCellWrapper(signedValue(value, '$'));
      },
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'metrics.adsetProfit',
      headerName: 'Profit (ad set)',
      cellRenderer: (params: any) => {
        const value = numberFormatter(params.value);
        return TextCellWrapper(signedValue(value, '$'));
      },
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'effectiveStatus',
      headerName: 'Status',
      width: 130,
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'vertical',
      headerName: 'Vertical',
      cellRenderer: TextCellWrapper,
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      filter: 'agDateColumnFilter',
      cellRenderer: TextCellWrapper,
      filterParams: {
        comparator: dateCellComparator,
      },
      comparator: dateCellSorter,
    },
    {
      field: 'editFBLink',
      headerName: 'Business Manager Link',
      minWidth: 180,
      cellRenderer: (params: any) => {
        const url = `https://business.facebook.com/adsmanager/manage/ads/edit?act=${params.data.account.id}&global_scope_id=153144895296430&nav_entry_point=bm_global_nav_shortcut&selected_ad_ids=${params.data.externalID}`;
        return <GenericCellWrapper
          valueComponent={
            <>
              Ad Edit<IconButton
              type={ButtonType.Secondary}
              icon={ExternalLinkIcon}
              onClick={() => onExternalLinkClick(url)}
            />
            </>

          }/>;
      },
      filter: false,
    },
    {
      field: 'reviewPostLink',
      headerName: 'Review Post Link',
      minWidth: 150,
      cellRenderer: (params: any) => {
        if (params.data.pageID && params.data.postID) {
          const url = `https://www.facebook.com/${params.data.pageID}/posts/${params.data.postID}/`;
          return <GenericCellWrapper
            valueComponent={
              <>
                Ad Review<IconButton
                type={ButtonType.Secondary}
                icon={ExternalLinkIcon}
                onClick={() => onExternalLinkClick(url)}
              />
              </>
            }/>;
        }
        return <div css={styles.center}>---</div>;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      cellRenderer: TextCellWrapper,
    },
  ];

  const shouldDisplayColumn = (fieldName: string) => {
    const limitedList = ['costRanking', 'primaryAssets.imageURL', 'primaryAssets.text', 'primaryAssets.headline', 'articleName', 'adsetName', 'metrics.ctr', 'owner', 'vertical', 'createdAt', 'editFBLink', 'reviewPostLink', 'type'];
    return limitedList.includes(fieldName);
  };

  if (displaySensitiveMetrics) {
    return allColumnsDefinition;
  }
  return allColumnsDefinition.filter(column => shouldDisplayColumn(column.field));
};

export const defaultColumnDefinition = {
  minWidth: 130
};
