/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { DropdownListItemProps, Filter, Loader, NoData, TableNew, Text } from '@ftbpro/mm-admin-ui-components';
import { ClickEventType } from '@ftbpro/mm-admin-ui-components/src/types';
import { useStyles } from '@ftbpro/mm-admin-core-hooks';
import { getStylesObject } from './creativeInsights.styles';
import '@ftbpro/mm-admin-ui-components/dist/bundle.css';
import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { CreativeInsightsItem } from './creativeInsights.types';
import '../../core/style/table.css';
import { CreativeInsightsServiceConfigsProvider } from '../../services/creativeInsights/CreativeInsightsServiceConfigsProvider';
import { formatCreativeInsights } from '../../services/creativeInsights/CreativeInsightsServiceFormatter';
import { CreativeInsightsTimeRange } from './creativeInsights.enums';
import { defaultColumnDefinition, getColumnsDefinition } from './creativeInsights.utils';
import { sortByNestedKey } from '../../core/utils/utils';
import { getUserDetails } from '../../core/utils/user.utils';


export const CreativeInsights = () => {
  const styles = useStyles(getStylesObject);
  const [isLoading, setIsLoading] = useState(false);
  const [creativeInsightsData, setCreativeInsightsData] = useState<CreativeInsightsItem[]>([]);
  const [timeRange, setTimeRange] = useState<CreativeInsightsTimeRange>(CreativeInsightsTimeRange.Last7Days);
  const userDetails = useMemo(() => getUserDetails(), []);
  const isExternalUser = userDetails.permissions.includes('external-access');

  useEffect(() => {
    setIsLoading(true);
    getCreativeInsights()
      .catch((error) => {
        setIsLoading(false);
      });
  }, [timeRange]);

  const getCreativeInsights = async () => {
    const creativeInsights = await CreativeInsightsServiceConfigsProvider.getCreativeInsights(timeRange);
    setIsLoading(false);
    setCreativeInsightsData(formatCreativeInsights(creativeInsights.ads));
  };

  const onTimeRangeChange = (e: ClickEventType, selectedItem: DropdownListItemProps) => {
    setTimeRange(selectedItem.value as CreativeInsightsTimeRange);
  };

  const onOrderByChange = (e: ClickEventType, selectedItem: DropdownListItemProps) => {
    const sortItems = [...sortByNestedKey(creativeInsightsData, 'metrics', (selectedItem as { value: string, id: string }).id)];
    setCreativeInsightsData(sortItems);
  };

  const getTimeRangeFilterOptions = () => Object.values(CreativeInsightsTimeRange);
  const orderByFilterOptions = [{ value: 'Revenue', id: 'adsetRevenue' }, { value: 'Profit', id: 'adsetProfit' }];

  return (
    <div css={styles.container}>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_XXXL}>Creative Insights for Facebook</Text>
      {!isLoading && <div css={styles.filter}>
        <Filter
          items={getTimeRangeFilterOptions()}
          selectedValue={timeRange}
          onSelectedChange={onTimeRangeChange}
        />
        {
          isExternalUser &&
          <Filter
            items={orderByFilterOptions}
            onSelectedChange={onOrderByChange}
            placeholder={'Order By'}
            style={styles.orderByFilter}
          />
        }
      </div>}
      {isLoading
        ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN}/>
        : <CreativeInsightsContent creativeInsightsData={creativeInsightsData} isExternalUser={isExternalUser}/>}
    </div>
  );
};


interface CreativeInsightsContentProps {
  creativeInsightsData: CreativeInsightsItem[];
  isExternalUser: boolean;
}

const CreativeInsightsContent = ({ creativeInsightsData, isExternalUser }: CreativeInsightsContentProps) => {
  const styles = useStyles(getStylesObject);
  const columnsDefinition = getColumnsDefinition(styles, !isExternalUser, creativeInsightsData.length);

  return (
    <div css={styles.contentWrapper}>
      {isEmpty(creativeInsightsData)
        ? <div css={styles.noDataWrapper}><NoData/></div>
        : <div css={styles.tableWrapper}>
          <TableNew
            data={creativeInsightsData}
            columnsDefinition={columnsDefinition}
            initialRowHeight={100}
            defaultColDef={defaultColumnDefinition}
            pagination
          />
        </div>
      }
    </div>
  );
};
